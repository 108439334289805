import React from "react";
import Hls from "hls.js";
import styled from "styled-components"

export default class VideoPlayer extends React.Component {
    state = {};

    componentDidMount() {
        const video = this.player;
        const hls = new Hls();
        const url = this.props.source

        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () { video.play(); });
    }
    render() {
        return (
            <Video
                ref={player => (this.player = player)}
                autoPlay={true}
                controls={true}
                loop={true}
                muted={true}
            />
        );
    }
}

const Video = styled.video`
    width: 100%;
`