import React from "react"
import styled from "styled-components"
import { SketchWrapper } from "../../../src/components/block-styles"
import loadable from "@loadable/component"

import CelBG from "./assets/CelAnimation-BG.jpg"
import CelBoy from "./assets/CelAnimation-Boy.png"
import CelBlanket from "./assets/CelAnimation-Blanket.png"

const Sketch = loadable(() => import("react-p5"))

export function AnimationCel(props) {
    let p5canvas

    let canvasShrink = 1
    let unit = 0

    let primaryColour
    let backgroundColour
    let transparentPrimaryColour

    let imgBG
    let imgBlanket
    let imgBoy

    let frameX = 0
    let frameY = 0

    let windowMidY = 0

    let cursorX = 0
    let cursorY = 0
    let easing = 0.1

    let xEase = 0
    let yEase = 0

    const preload = (p5) => {
        imgBG = p5.loadImage(CelBG)
        imgBlanket = p5.loadImage(CelBlanket)
        imgBoy = p5.loadImage(CelBoy)
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5canvas = p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)

        p5.frameRate(60)
        p5.rectMode(p5.CENTER)
        p5.imageMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)

        imgBG.loadPixels()
        imgBlanket.loadPixels()
        imgBoy.loadPixels()

        primaryColour = p5.color(props.primaryColour)
        transparentPrimaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)

        unit = props.aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;
        canvasShrink = p5.height / p5canvas.canvas.getBoundingClientRect().height

        cursorX = -unit * 50
        cursorY = -unit * 25
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(backgroundColour)
        p5.fill(primaryColour)

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text("Sketch Width: " + p5.width, 8, 40)
        // p5.text("Sketch Height: " + p5.height, 8, 64)
        // p5.text("Unit: " + unit, 8, 88)

        if (p5.frameCount % 30) {
            windowMidY = p5.constrain((p5.windowHeight / 2 - p5canvas.canvas.getBoundingClientRect().y) * canvasShrink - p5.height / 2, -p5.height / 2, p5.height / 2)

            let targetX = cursorX
            let dX = targetX - frameX
            frameX += dX * easing

            let targetY = windowMidY
            let dY = targetY - frameY
            frameY += dY * easing

            xEase = p5.sin(((frameY / p5.height - 0.5) * -90))
            yEase = ((p5.sin(((frameY / p5.height - 0.5) * 180 - 90)) / 2) + 0.5)

        }

        p5.translate(p5.width / 2, p5.height / 2)
        p5.strokeWeight(unit / 10)

        // Small cursor to visualise where windowMidY is
        //p5.rect(-p5.width / 2 + 32, frameY, 10, 10)

        //yEase = 0

        transparentPrimaryColour.setAlpha(128)
        p5.stroke(transparentPrimaryColour)
        p5.fill(transparentPrimaryColour)
        p5.noFill()

        p5.rotate(-xEase);
        p5.image(imgBG, xEase * unit, yEase * unit, 56 * unit, 31.5 * unit)
        p5.rect(xEase * unit, yEase * unit, 56 * unit, 31.5 * unit)

        p5.rotate(-xEase);
        p5.image(imgBlanket, xEase * 4 * unit, yEase * 4 * unit, 56 * unit, 31.5 * unit)
        p5.rect(xEase * 4 * unit, yEase * 4 * unit, 56 * unit, 31.5 * unit)

        p5.rotate(-xEase);
        p5.image(imgBoy, xEase * 8 * unit, yEase * 8 * unit, 56 * unit, 31.5 * unit)
        p5.rect(xEase * 8 * unit, yEase * 8 * unit, 56 * unit, 31.5 * unit)


        // p5.text("frameYRaw: " + frameY / p5.height * 2, 8, -48)
        // p5.text("frameYRaw: " + ((frameY / p5.height * 2 - 0.5)), 8, -32)
        // p5.text("frameY: " + ((p5.sin(0) + 1)), 8, -16)
        // p5.text("xEase: " + (xEase), 8, 0)
        // p5.text("yEase: " + (yEase), 8, 16)
    }

    const mouseMoved = (p5) => {
        cursorX = p5.mouseX - p5.width / 2
        cursorY = p5.mouseY - p5.height / 2
    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio)
        canvasShrink = p5.height / p5canvas.canvas.getBoundingClientRect().height

        unit = props.aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;
    }

    return (
        <SketchWrapper>
            <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} mouseMoved={mouseMoved} />
        </SketchWrapper>
    )
};

export function CelWaste(props) {
    let p5canvas

    let primaryColour
    let backgroundColour
    let transparentPrimaryColour

    let canvasShrink = 1
    let unit = 0

    let frameX = 0
    let frameY = 0

    let cursorX = 0
    let cursorY = 0

    let imgBlanket
    let wasteTexture

    let windowMidY
    let easing = 0.05
    let progress = 0

    const preload = (p5) => {
        imgBlanket = p5.loadImage(CelBlanket)
    }

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5canvas = p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)
        p5.frameRate(60)
        p5.rectMode(p5.CENTER)
        p5.imageMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)

        unit = props.aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;
        canvasShrink = p5.height / p5canvas.canvas.getBoundingClientRect().height

        imgBlanket.loadPixels()

        primaryColour = p5.color(props.primaryColour)
        transparentPrimaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)

        unit = props.aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;

        wasteTexture = p5.createGraphics(56 * unit, 31.5 * unit)
        wasteTexture.angleMode(p5.DEGREES)
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(backgroundColour)
        p5.fill(primaryColour)

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text(windowMidY, 8, 16)
        // p5.text(progress, 8, 32)
        // p5.text(frameY - p5.height / 2, 8, 64)
        // p5.text(p5.sin(frameY), 8, 88)

        if (p5.frameCount % 30) {
            windowMidY = p5.constrain((p5.windowHeight / 2 - p5canvas.canvas.getBoundingClientRect().y) * canvasShrink - p5.height / 2, -p5.height / 2, p5.height / 2)

            let targetX = cursorX
            let dX = targetX - frameX
            frameX += dX * easing

            let targetY = windowMidY
            let dY = targetY - frameY
            frameY += dY * easing

            progress = windowMidY ? p5.map(frameY, -p5.height / 2, p5.height / 2, 0, 100) : 0;
        }

        p5.translate(p5.width / 2, p5.height / 2)

        p5.stroke(backgroundColour)
        p5.strokeWeight(unit / 10)

        p5.stroke(transparentPrimaryColour)

        let stripeSpacing = wasteTexture.width / 50

        wasteTexture.push()
        wasteTexture.translate(wasteTexture.width / 2, wasteTexture.height / 2)
        wasteTexture.rotate(45)

        transparentPrimaryColour.setAlpha(p5.map(progress, 0, 100, 0, 128))
        wasteTexture.stroke(transparentPrimaryColour)
        wasteTexture.background(backgroundColour)

        wasteTexture.strokeWeight(unit / 10)
        for (let i = 0; i < 100; i++) {
            wasteTexture.line(-wasteTexture.width + stripeSpacing * i, wasteTexture.height, -wasteTexture.width + stripeSpacing * i, -(wasteTexture.height * 2 / 100) * progress + i * (wasteTexture.height * 2 / 200))
        }
        wasteTexture.pop()

        p5.image(wasteTexture, 0, 0, 56 * unit, 31.5 * unit)




        transparentPrimaryColour.setAlpha(128)
        p5.stroke(transparentPrimaryColour)
        p5.fill(transparentPrimaryColour)
        p5.noFill()


        transparentPrimaryColour.setAlpha(p5.map(progress, 0, 100, 0, 128))

        p5.rect(0, 0, 56 * unit, 31.5 * unit)

        p5.stroke(transparentPrimaryColour)
        p5.fill(backgroundColour)
        p5.rect(-10.5 * unit, 3.75 * unit, 18 * unit, 10 * unit)

        p5.image(imgBlanket, 0, 0, 56 * unit, 31.5 * unit)


        // Cursor for scroll debugging
        //p5.rect(-p5.width / 2 + 32, frameY, 10, 10)

    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio)
        canvasShrink = p5.height / p5canvas.canvas.getBoundingClientRect().height

        unit = props.aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;
    }

    return (
        <SketchWrapper>
            <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
        </SketchWrapper>
    )
};