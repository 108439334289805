import React from "react"
import styled from "styled-components"
import { SketchWrapper } from "../../../src/components/block-styles"
import loadable from "@loadable/component"

const Sketch = loadable(() => import("react-p5"))

export function IntroSketch(props) {
    let primaryColour
    let backgroundColour

    let canvasShrink = 1
    let unit = 0

    let disc

    const setup = (p5, canvasParentRef) => {
        // use parent to render the canvas in this ref
        // (without that p5 will render the canvas outside of your component)
        p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)
        p5.frameRate(60)
        p5.rectMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)

        primaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)

        disc = new Disc(p5, 0, 0, 40, 14, 4);

        unit = setUnit(p5, props.aspectRatio)
    }

    const draw = (p5) => {
        // NOTE: Do not use setState in the draw function or in functions that are executed
        // in the draw function...
        // please use normal variables or class properties for these purposes
        p5.background(backgroundColour)
        p5.fill(primaryColour)

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text("Sketch Width: " + p5.width, 8, 40)

        p5.translate(p5.width / 2, p5.height / 2)
        p5.stroke(backgroundColour)
        p5.strokeWeight(unit / 10)

        disc.move(unit)
        disc.display(unit)
    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio)
        unit = setUnit(p5, props.aspectRatio)
    }

    return (
        <SketchWrapper>
            <Sketch setup={setup} draw={draw} windowResized={windowResized} />
        </SketchWrapper>
    )
};

export function BreakdownSketch(props) {
    let primaryColour
    let backgroundColour

    let discLength
    let discRate
    let discSpeed

    let unit = 0

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)
        p5.frameRate(60)
        p5.rectMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)

        primaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)

        unit = setUnit(p5, props.aspectRatio)

        discLength = new Disc(p5, -30, 0, 24, 14, 0);
        discRate = new Disc(p5, 0, 0, 24, 14, 25);
        discSpeed = new Disc(p5, 30, 0, 24, 14, 0);
    }

    const draw = (p5) => {
        p5.background(backgroundColour)
        p5.fill(primaryColour)

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text("Sketch Width: " + p5.width, 8, 40)


        p5.translate(p5.width / 2, p5.height / 2)
        p5.stroke(backgroundColour)
        p5.strokeWeight(unit / 10)

        discLength.move(unit);
        discLength.display(unit);
        discRate.move(unit);
        discRate.display(unit);
        discSpeed.move(unit);
        discSpeed.display(unit);
    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth / 2)
        unit = setUnit(p5, props.aspectRatio)
    }

    return (
        <SketchWrapper>
            <Sketch setup={setup} draw={draw} windowResized={windowResized} />
        </SketchWrapper>
    )
};

export function SpeedComparison(props) {
    let slider
    let label

    let primaryColour
    let backgroundColour
    let numSlices = 24
    let rpm = 30
    let rotation = 0

    let unit = 0

    let disc

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)
        p5.frameRate(60)
        p5.rectMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)

        label = p5.createElement('div', 'RPM: ' + rpm).parent(canvasParentRef)
        label.addClass('slider-label')

        slider = p5.createSlider(15, 45, 30, 0.1).parent(canvasParentRef)
        //slider.position(10, 10);
        slider.style('width', '256px');

        primaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)

        unit = setUnit(p5, props.aspectRatio)

        disc = new Disc(p5, 0, 0, 40, 14, 4);
    }

    const draw = (p5) => {
        p5.background(backgroundColour)
        p5.fill(primaryColour)

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text("Sketch Width: " + p5.width, 8, 40)

        p5.translate(p5.width / 2, p5.height / 2)
        p5.stroke(backgroundColour)
        p5.strokeWeight(unit / 10)

        rpm = slider.value()

        label.html('RPM: ' + rpm)

        disc.setRPM(rpm)
        disc.move(unit)
        disc.display(unit)
    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth / 2)
        unit = setUnit(p5, props.aspectRatio)
    }

    return (
        <SketchWrapper>
            <Sketch setup={setup} draw={draw} windowResized={windowResized} />
        </SketchWrapper>
    )
};

export function SketchC(props) {
    let primaryColour
    let backgroundColour

    let unit = 0

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(p5.windowWidth, p5.windowWidth * props.aspectRatio).parent(canvasParentRef)
        p5.rectMode(p5.CENTER)
        p5.angleMode(p5.DEGREES)
        p5.frameRate(60)

        primaryColour = p5.color(props.primaryColour)
        backgroundColour = p5.color(props.backgroundColour)
    }

    const draw = (p5) => {
        p5.background(backgroundColour)
        p5.fill(primaryColour)
        p5.noStroke()

        p5.textSize(16)
        // p5.text(p5.getFrameRate(), 8, 16)
        // p5.text("Sketch Width: " + p5.width, 8, 40)

        p5.translate(p5.width / 2, p5.height / 2)
        p5.rotate(p5.frameCount)
        p5.rect(0, 0, p5.height / 2)
    }

    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowWidth / 2)
        unit = setUnit(p5, props.aspectRatio)
    }

    return (
        <SketchWrapper>
            <Sketch setup={setup} draw={draw} windowResized={windowResized} />
        </SketchWrapper>
    )
};

function setUnit(p5, aspectRatio) {
    return aspectRatio >= 1 ? p5.height / 100 : p5.width / 100;
}

function Disc(p5, x, y, diameter, numSlices, rpm) {
    this.x = x
    this.y = y
    this.diameter = diameter
    this.numSlices = numSlices
    this.rpm = rpm
    this.rotation = 0

    this.move = function (unit) {
        this.rotation += (360 * this.rpm) / 60 / 60
    }

    this.display = function (unit) {
        p5.push()

        p5.translate(this.x * unit, this.y * unit)
        p5.rotate(this.rotation)

        p5.ellipse(0, 0, this.diameter * unit, this.diameter * unit)

        for (let i = 0; i < numSlices; i++) {
            p5.rotate(360 / numSlices)
            p5.line(0, 0, 0, this.diameter / 2 * unit)
        }
        p5.pop()
    }

    this.setRPM = function (rpm) {
        this.rpm = rpm
    }
}