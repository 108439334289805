import React from "react"
import styled from "styled-components"
import StreamingVideo from "./streaming-video"
// import ReactHlsPlayer from "react-hls-player"

export const GriddedH1 = props => (
  <GridHeading1 {...props} />
)
export const GriddedH2 = props => (
  <GridHeading2 {...props} />
)

export const GriddedH3 = props => (
  <GridHeading3 {...props} />
)

export const GriddedP = props => (
  <GridParagraph
    {...props}
  />
)

export const GriddedMeta = props => (
  <GridMeta
    {...props}
  />
)

export const GriddedInlineCode = props => (
  <GridInlineCode {...props} />
)

export const GriddedImg = props => (
  <React.Fragment>
    {props.className === "gatsby-resp-image-image" ? (
      <img {...props} alt="" />
    ) : (
      <MediaBlock>
        <GridImage {...props} />
      </MediaBlock>
    )}
  </React.Fragment>
)

export const GriddedSpan = props => {
  return (
    <React.Fragment>
      {props.className === "gatsby-resp-image-wrapper" ? (
        <FluidImageWrap>
          <span {...props} />
        </FluidImageWrap>
      ) : (
        <span {...props} />
      )}
    </React.Fragment>
  )
}

export const Gallery = ({ children, ...props }) => {
  return <GalleryBlock>{children}</GalleryBlock>
}

export function ArticleGrid({ children, ...props }) {
  return <GridBlock as="article" style={{
    'paddingTop': "10vh"
  }}> {children}</GridBlock >
}

export const Video = ({ children, ...props }) => {
  return (
    <VideoWrap>
      <StreamingVideo source={props.streamingSource}>Video in here</StreamingVideo>
      {/* <ReactHlsPlayer
        src={props.streamingSource}
        autoPlay={true}
        controls={true}
        muted={true}
        loop={true}
        width="100%"
        height="auto"
      /> */}
    </VideoWrap>
  )
}

const GridBlock = styled.div`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);
  row-gap: var(--gridColumnGap);
  padding: 0 var(--gridMarginGap);

  grid-template-areas: "a a a a a a";

  @media screen and (min-width: 768px) {
    grid-template-areas: ". . a a a a a a . b b b";
  }
`

const VideoWrap = styled.div`

  grid-column-start: 1;
  grid-column-end: -1;

  padding: calc(var(--gridMarginGap) * 2) 0;

  @media screen and (min-width: 768px) {
    grid-column-start: 2;
    grid-column-end: -2;
  }
`

const GalleryBlock = styled.section`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);

  grid-template-columns: repeat(3, 1fr);

  grid-column-start: 1;
  grid-column-end: -1;

  padding: calc(var(--gridMarginGap) * 2) 0;

  /* grid-template-areas: "a a a a a a"; */

  @media screen and (min-width: 768px) {
    /* grid-template-areas: "a a a a b b b b c c c c"; */
    grid-template-columns: repeat(3, 1fr);
  }
`

const MediaBlock = styled.div`
  display: grid;
  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);

  grid-template-areas: "a a a a a a";

  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (min-width: 768px) {
    grid-template-areas: ". . a a a a a a a a . .";
  }
`

const GridInlineCode = styled.div`
  font-size: var(--step-1-75);

  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }
  
  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }
  
  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }

  & .katex-html {
    text-align: left;
  }
`
const GridHeading1 = styled.h1`
  font-size: var(--step-5);
  font-weight: 500;
  line-height: 1;

  grid-column-start: 1;
  grid-column-end: -1;

  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }
  
  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }
  
  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }
`
const GridHeading2 = styled.h2`
  grid-column-start: 1;
  grid-column-end: -1;

  padding: var(--gridColumnGap) 0;

  font-size: var(--step-2-5);
  font-weight: 500;
  
  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }

  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }

  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }
`
const GridHeading3 = styled.h3`
  grid-column-start: 1;
  grid-column-end: -1;
  
  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }

  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }

  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }
`

const GridParagraph = styled.p`
  grid-column-start: 1;
  grid-column-end: -1;

  font-size: var(--step-2);
  line-height: 1.4;

  margin-bottom: var(--gridColumnGap);

  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }

  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }

  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }
`

const GridMeta = styled.span`
  grid-column-start: 1;
  grid-column-end: -1;

  font-size: var(--step-1-75);
  line-height: 1.4;

  margin: var(--gridMarginGap) 0;

  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: 10;
  }

  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }

  @media screen and (min-width: 2000px) {  
    grid-column-start: 3;
    grid-column-end: 8;
  }
`

const GridImage = styled.img`

  width: 100%;
  height: auto;

  grid-column-start: 1;
  grid-column-end: -1;
  
  @media screen and (min-width: 768px) {  
    grid-column-start: 3;
    grid-column-end: 9;
  }

`

const FluidImageWrap = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  padding: calc(var(--gridMarginGap) * 2) 0;

  @media screen and (min-width: 768px) {  
    grid-column-start: 2;
    grid-column-end: -2;
  }
  
  @media screen and (min-width: 1280px) {  
    grid-column-start: 3;
    grid-column-end: 11;
  }

  ${GalleryBlock} & {
    display: block;

    grid-column: span 1;
  }
`

export const SketchWrapper = styled.div`
    position: relative;

    /* display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    column-gap: var(--gridColumnGap); */

    grid-column-start: 1;
    grid-column-end: -1;

    padding: calc(var(--gridMarginGap) * 2) 0;

    @media screen and (min-width: 1280px) {  
      grid-column-start: 2;
      grid-column-end: 12;
    }

    @media screen and (min-width: 2000px) {  
      grid-column-start: 3;
      grid-column-end: 11;
    }

    & .slider-label {
        padding: var(--gridMarginGap) 0 calc(var(--gridMarginGap) / 4);
        font-size: 16px;
        font-variant-numeric: tabular-nums;
    }

    & > div.react-p5 {

        & > canvas {
            height: auto !important;
        }
    }
`